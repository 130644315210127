import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
  
export class ConfigComponent implements OnInit {
  camposLogin = {
    infoLogin: "Preencha os campos abaixo",
    permiteRegistro: true,
    textoBotaoLogin: "Login",
    textoBotaoRegistro: "Registre-se",
    classeBotaoLogin: "btn btn-block btn-warning",
    classeBotaoRegistro: "btn btn-block btn-primary",
    placeholderLogin: "Login",
    urlAposLogin: "/",
    textoBotaoEsqueciMinhaSenha: "Esqueci minha senha",
  }

  camposEsqueciMinhaSenha = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    infoEsqueciMinhaSenha: "Digite o seu login e clique no botão abaixo",
    placeholderLogin: "Login",
    classeBotaoLogin: "btn btn-block btn-warning",
    textoBotaoConfirmaEsqueciMinhaSenha: "Resgatar senha",
  }

  confirmacaoSenha = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    infoCodigoEsqueciMinhaSenha: "Insira o seu nome de usuário, sua nova senha e o código recebido por email",
    placeholderCodigoEsqueciMinhasSenha: "Código",
    placeholderNovaSenha: "Nova senha",
    textoBotaoCodigoEsqueciMinhasSenha: "Alterar senha",
    textoBotaoReenviaCodigo: "Reenviar",
    classeBotaoLogin: "btn btn-block btn-warning",
    placeholderUsername: "Nome de usuário",
    infoCodigoRegistro: "Insira o nome de usuário e o código recebido:",
    placeholderCodigoRegistro: "Código",
    textoErroRecuperarSenha: "Não foi possível recuperar sua senha. Tente novamente mais tarde.",
    textoSucessoRecuperarSenha: "Senha alterada com sucesso. Faça o login novamente.",
  }

  inputSenha = {
    infoSenha: "A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, um número e um símbolo",
    placeholderSenha: "Senha",
    placeholderConfirmacaoSenha: "Confirme sua senha",
  }

  registro = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    placeholderRegistroUsername: "Nome de usuário",
    placeholderRegistroSenha: "Senha",
    placeholderRegistroConfirmacaoSenha: "Confirme a senha",
    placeholderRegistroTelefone: "Telefone",
    placeholderRegistroEmail: "Email",
    placeholderRegistroNomeCompleto: "Nome completo",
    placeholderRegistroDocumento: "CPF (apenas números)",
    placeholderRegistroFoto: "Foto",
    textoBotaoRegistroEnviar: "Registrar",
    textoUsernameDisponivel: "Nome de usuário liberado",
    textoUsernameOcupado: "Este nome de usuário não está disponível. Por favor, escolha outro.",
    textoErroRegistroUsuario: "Não foi possível concluir o registro. Por favor, tente novamente mais tarde.",
    classeBotaoLogin: "btn btn-block btn-warning",
  }

  confirmacaoRegistro = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    classeBotaoLogin: "btn btn-block btn-warning",
    placeholderUsername: "Nome de usuário",
    infoCodigoRegistro: "Insira o nome de usuário e o código recebido:",
    placeholderCodigoRegistro: "Código",
    textoSucessoConfirmaRegistroUsuario: "Usuário registrado com sucesso.",
    textoErroConfirmaRegistroUsuario: "Erro ao registrar usuário. Por favor, tente novamente mais tarde.",
    textoBotaoCodigoEsqueciMinhasSenha: "Confirmar código",
    textoBotaoReenviaCodigo: "Reenviar",
  }

  confirmacaoRegistroSocial = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    classeBotaoLogin: "btn btn-block btn-warning",
    placeholderUsername: "Nome de usuário",
    infoCodigoRegistro: "Você precisa confirmar o seu cadastro. Insira o código recebido:",
    placeholderCodigoRegistro: "Código",
    textoSucessoConfirmaRegistroUsuario: "Usuário registrado com sucesso.",
    textoErroConfirmaRegistroUsuario: "Erro ao registrar usuário. Por favor, tente novamente mais tarde.",
    textoBotaoCodigoEsqueciMinhasSenha: "Confirmar código",
    textoBotaoReenviaCodigo: "Reenviar",
  }

  registrarSocial = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    classeBotaoLogin: "btn btn-block btn-warning",
    placeholderRegistroTelefone: "Confirme o seu telefone",
    placeholderRegistroEmail: "Confirme o seu email",
    placeholderRegistroDocumento: "Informe seu CPF",
    textoBotaoRegistroEnviar: "Registrar",
  }

  usuario = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    classeBotaoLogin: "btn btn-block btn-warning",
    placeholderRegistroTelefone: "Confirme o seu telefone",
    placeholderRegistroEmail: "Confirme o seu email",
    placeholderRegistroDocumento: "Informe seu CPF",
    textoBotaoRegistroEnviar: "Registrar",
  }

  logout = {
    logoURL: "https://www.doutorsofa.com.br/img/site/logo4.png",
    classeBotaoLogin: "btn btn-block btn-warning",
  }


  constructor() { }

  ngOnInit(): void {
  }



}
