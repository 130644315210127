import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from './../../config/config.component';

@Component({
  selector: 'app-input-senha',
  templateUrl: './input-senha.component.html',
  styleUrls: ['./input-senha.component.css']
})
export class InputSenhaComponent implements OnInit {
  conf: any = {}
  senha: string = ""
  tipo = "1"
  mostrarSenhaTipo1: boolean = false
  mostrarSenhaTipo2: boolean = false
  mostrarSenhaTipoConfirmacao: boolean = false


  constructor(
    private configs: ConfigComponent,
  ) {
    this.conf = this.configs.inputSenha
  }

  ngOnInit(): void {
  }

  alteraTipo(tipo = "") {
    this.tipo = tipo
  }

  validaInputSenha() {
    var enviar = true
    //checa se a senha tem menos de x caracteres
    if (this.senha.length < 8) {
      enviar = false
    }

    //checa se a senha tem alguma letra maiúscula
    if (this.senha == this.senha.toLowerCase()) {
      enviar = false
    }

    if (!/[(@!#\$%\^\&*\)\(+=._-]{1,}/.test(this.senha)) {
      enviar = false
    }

    if (!/[0-9]{1,}/.test(this.senha)) {
      enviar = false
    }

    return enviar
  }

  toggleMostrarSenhaTipo1() {
    if (!this.mostrarSenhaTipo1) {
      $("#senhaTipo1").attr("type","text")
    } else {
      $("#senhaTipo1").attr("type","password")
    }
  }

  toggleMostrarSenhaTipo2() {
    if (!this.mostrarSenhaTipo2) {
      $("#senhaTipo2").attr("type","text")
    } else {
      $("#senhaTipo2").attr("type","password")
    }
  }

  toggleMostrarSenhaTipoConfirmacao() {
    if (!this.mostrarSenhaTipoConfirmacao) {
      $("#senhaTipoConfirmacao").attr("type","text")
    } else {
      $("#senhaTipoConfirmacao").attr("type","password")
    }
  }

}
