import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { ConfigComponent } from '../config/config.component';
import { ActivatedRoute, Router } from "@angular/router"

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  conf: any = {}
  usuario: any = {}
  url: string = ""

  constructor(
    private loginService: LoginService,
    private configs: ConfigComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.conf = this.configs.logout

    this.activatedRoute.queryParams.
      subscribe({
        next: valores => {
          if (typeof valores.redirectTo != 'undefined') {
            this.url = valores.redirectTo
          } else {
            this.url = "REDIRECTTO"
          }
        }
      })
  }

  ngOnInit(): void {
    this.logOut()
  }

  logOut() {
    this.loginService.logout()
      .subscribe(resultado => this.analisaResultadoLogout(resultado))
  }

  globalLogOut() {
    this.loginService.globalLogout()
      .subscribe(resultado => this.analisaResultadoLogout(resultado))
  }

  analisaResultadoLogout(resultado) {
    let logout = this
    window.location.href = 'REDIRECTTO/login/?redirectTo=' + this.url
  }


}
