<div class="">

  <div class="">
    <div class="">

      <div class="text-center">
        <img src="{{usuario.picture}}" alt="" width="25%" style="border-radius: 50%;">
      </div>

      <div style="margin-top: 30px">
        <p>
          <b>Nome:</b> {{usuario.name}}
        </p>
        <p>
          <b>Email:</b> {{usuario.email}}
        </p>
        <p>
          <b>Telefone:</b> {{usuario.phone_number}}
        </p>

        <p>
          <b>Grupos: </b>
        </p>
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let grupo of usuario.groups" >
              {{grupo}}
            </li>
          </ul>
        
      </div>

      <div>
        <button *ngIf="verificaSePodeAlterarSenha()" class="btn btn-block btn-default" (click)="alterarSenha()">
          Alterar senha
        </button>
        <button class="btn btn-block btn-danger" (click)="logOut()">
          Sair
        </button>
        <button class="btn btn-block btn-danger" (click)="globalLogOut()">
          Sair de todos os dispositivos
        </button>
      </div>
      
    </div>
  </div>
</div>
