import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AlertaComponent } from './alerta/alerta.component';
import { LoginService } from './login.service';
import { AreaRestritaComponent } from './area-restrita/area-restrita.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { CamposLoginComponent } from './login/campos-login/campos-login.component';
import { ConfigComponent } from './config/config.component';
import { EsqueciMinhaSenhaComponent } from './esqueci-minha-senha/esqueci-minha-senha.component';
import { RegistroComponent } from './registro/registro.component';
import { InputSenhaComponent } from './assets/input-senha/input-senha.component';
import { BtnVoltarLoginComponent } from './assets/btn-voltar-login/btn-voltar-login.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { BtnConfirmarCodigoComponent } from './assets/btn-confirmar-codigo/btn-confirmar-codigo.component';
import { ConfirmacaoCodigoEsqueciMinhaSenhaComponent } from './confirmacao-codigo-esqueci-minha-senha/confirmacao-codigo-esqueci-minha-senha.component';
import { BtnConfirmarCodigoEsqueciMinhaSenhaComponent } from './assets/btn-confirmar-codigo-esqueci-minha-senha/btn-confirmar-codigo-esqueci-minha-senha.component';
import { RegistrarSocialComponent } from './registrar-social/registrar-social.component';
import { ConfirmacaoUsuarioSocialComponent } from './confirmacao-usuario-social/confirmacao-usuario-social.component';
import { createCustomElement } from '@angular/elements';
import { UsuarioComponent } from './usuario/usuario.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertaComponent,
    AreaRestritaComponent,
    CamposLoginComponent,
    ConfigComponent,
    EsqueciMinhaSenhaComponent,
    RegistroComponent,
    InputSenhaComponent,
    BtnVoltarLoginComponent,
    ConfirmacaoComponent,
    BtnConfirmarCodigoComponent,
    ConfirmacaoCodigoEsqueciMinhaSenhaComponent,
    BtnConfirmarCodigoEsqueciMinhaSenhaComponent,
    RegistrarSocialComponent,
    ConfirmacaoUsuarioSocialComponent,
    UsuarioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule
  ],
  providers: [
    LoginService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2429095357390943'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    ConfigComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    const myElement = createCustomElement(AppComponent, { injector });
    customElements.define('app-element', myElement);
  }
  ngDoBootstrap() {}
 }
