<div class="">

  <div class="">
    <div class="">


      <div id="containerRegistro" *ngIf="step == 'checaUsername'">
        <app-alerta #alerta></app-alerta>
        {{conf.infoRegistro}}

        <!-- username -->
        <div class="form-group">
          <label for="inputRegistroUsername">{{conf.placeholderUsername}}</label>
          <input type="text" autocomplete="off" class="form-control" id="inputRegistroUsername" [(ngModel)]="username"
            placeholder="{{conf.placeholderRegistroUsername}}">
        </div>

        <button class="btn btn-block btn-primary" [disabled]="!validaNomeUsuario()"
          (click)="verificaSeUsernameEstaDisponivel()">Próximo</button>
        <app-btn-voltar-login></app-btn-voltar-login>
        <app-btn-confirmar-codigo></app-btn-confirmar-codigo>

      </div>

      <div id="containerRegistro2" [hidden]="step != 'preencheInfos'">
        <app-alerta #alerta></app-alerta>
        <p>
          <b>Nome de usuário:</b> {{username}}
        </p>
        <!-- senha  -->
        <app-input-senha #inputSenha></app-input-senha>

        <!-- confirmação da senha  -->
        <app-input-senha #inputSenhaConfirmacao></app-input-senha>

        <input type="checkbox" name="mostrarSenhas" id="mostrarSenhas" [(ngModel)]="mostrarSenhas"
          (click)="toggleMostrarSenhas()">
        <label style="margin-left: 5px;" for="mostrarSenhas">Mostrar senhas</label>
        <div style="margin-bottom: 20px"></div>
        <hr>
        <div style="margin-bottom: 20px"></div>

        <!-- telefone  -->
        <div class="form-group">
          
          <!-- <input type="text" class="form-control" id="inputRegistroTelefone" [(ngModel)]="telefone" -->
            <!-- placeholder="{{conf.placeholderRegistroTelefone}}"> -->
            <form #f="ngForm" [formGroup]="telefoneForm">
              <label for="inputRegistroTelefone">{{conf.placeholderRegistroTelefone}}</label><br>
              <ngx-intl-tel-input
                    [cssClass]="'form-control'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [preferredCountries]="preferredCountries"
                    [searchCountryFlag]="false"
                    [selectFirstCountry]="true"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [inputId]="inputRegistroTelefone"
                    [separateDialCode]="true"
                    name="telefone"
                    formControlName="telefone"
              ></ngx-intl-tel-input>
              </form>

              <div *ngIf="!validaNumeroTelefone()" id="numeroTelefoneInvalido" class="text-red" style="color: red; font-size: 10px"> O número é inválido. Selecione o país e utilize o formato (ddd) 99999-9999</div>
              <div *ngIf="validaNumeroTelefone()" id="numeroTelefoneValido" class="text-green" style="color: green; font-size: 10px"> O número é válido</div>
        </div>

        <!-- email  -->
        <div class="form-group">
          <label for="inputRegistroEmail">{{conf.placeholderRegistroEmail}}</label>
          <input autocomplete="no" type="text" class="form-control" id="inputRegistroEmail" [(ngModel)]="email"
            placeholder="{{conf.placeholderRegistroEmail}}">
        </div>

        <!-- nome completo  -->
        <div class="form-group">
          <label for="inputRegistroNomeCompleto">{{conf.placeholderRegistroNomeCompleto}}</label>
          <input autocomplete="no" type="text" class="form-control" id="inputRegistroNomeCompleto" [(ngModel)]="nomeCompleto"
            placeholder="{{conf.placeholderRegistroNomeCompleto}}">
        </div>

        <!-- CPF  -->
        <div class="form-group">
          <label for="inputRegistroDocumento">{{conf.placeholderRegistroDocumento}}</label>
          <input autocomplete="no" type="text" class="form-control" id="inputRegistroDocumento" [(ngModel)]="documento"
            placeholder="{{conf.placeholderRegistroDocumento}}">
        </div>

        <button class="{{conf.classeBotaoLogin}}" [disabled]="!validaInputRegistro()" (click)="registroEnviar()">
          {{conf.textoBotaoRegistroEnviar}}
        </button>
        <app-btn-voltar-login></app-btn-voltar-login>
        <app-btn-confirmar-codigo></app-btn-confirmar-codigo>
      </div>

    </div>
  </div>
</div>
