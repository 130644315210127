<div class="">

  <div class="">
    <div class="">

      <div class="text-center">
        <app-alerta #alerta></app-alerta>
        <img src="{{user.photoUrl}}" alt="" class="socialPicture">
        <p style="margin-top: 10px;">
            <b>{{user.name}}</b>
        </p>
      </div>
      <!-- telefone  -->
      <div class="form-group">
        <label for="inputRegistroTelefone">{{conf.placeholderRegistroTelefone}}</label>
        <input type="text" class="form-control" id="inputRegistroTelefone" [(ngModel)]="user.telefone"
          placeholder="{{conf.placeholderRegistroTelefone}}">
      </div>
      <!-- email  -->
      <div class="form-group">
        <label for="inputRegistroEmail">{{conf.placeholderRegistroEmail}}</label>
        <input type="text" class="form-control" id="inputRegistroEmail" [(ngModel)]="user.email"
          placeholder="{{conf.placeholderRegistroEmail}}">
      </div>

      <div class="form-group">
        <label for="inputRegistroDocumento">{{conf.placeholderRegistroDocumento}}</label>
        <input type="text" class="form-control" id="inputRegistroDocumento" [(ngModel)]="user.documento"
          placeholder="{{conf.placeholderRegistroDocumento}}">
      </div>

      <button class="{{conf.classeBotaoLogin}}" [disabled]="!validaInputRegistro()" (click)="registroEnviar()">
        {{conf.textoBotaoRegistroEnviar}}
      </button>
      <app-btn-voltar-login></app-btn-voltar-login>
    <app-btn-confirmar-codigo></app-btn-confirmar-codigo>
    </div>
  </div>
</div>
