import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-confirmar-codigo-esqueci-minha-senha',
  templateUrl: './btn-confirmar-codigo-esqueci-minha-senha.component.html',
  styleUrls: ['./btn-confirmar-codigo-esqueci-minha-senha.component.css']
})
export class BtnConfirmarCodigoEsqueciMinhaSenhaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
