<div class="">

  <div class="">
    <div class="">

      <div id="containerRegistro3">
        <app-alerta #alerta></app-alerta>
        <p>
            {{conf.infoCodigoRegistro}}
        </p>

        <div class="form-group">
          <label for="inputUsername">{{conf.placeholderUsername}}</label>
          <input type="text" autocomplete="off" class="form-control" id="inputUsername" [(ngModel)]="username"
            placeholder="{{conf.placeholderUsername}}">
        </div>

        <div class="input-group">
          <span class="input-group-addon"
            id="inputCodigoRegistro">{{conf.placeholderCodigoRegistro}}</span>
          <input type="text" class="form-control" [(ngModel)]="codigo"
            placeholder="{{conf.placeholderCodigoRegistro}}" aria-describedby="basic-addon1">
        </div>
     
        <button class="btn btn-{{conf.classeBotaoLogin}}" [disabled]="!validaInputsCodigoRegistro()"
          (click)="confirmaCodigoRegistro()">
          {{conf.textoBotaoCodigoEsqueciMinhasSenha}}
        </button>

        <button class="{{conf.classeBotaoLogin}}" (click)="reenviaCodigo()" [disabled]="!validaInputsReenviaCodigo()">
          {{conf.textoBotaoReenviaCodigo}}
        </button>
        <app-btn-voltar-login></app-btn-voltar-login>
      </div>
    </div>
  </div>
</div>
