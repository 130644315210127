import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-area-restrita',
  templateUrl: './area-restrita.component.html',
  styleUrls: ['./area-restrita.component.css']
})
export class AreaRestritaComponent implements OnInit {
  title="não está logado"
  apiURL: string
  success: boolean

  headers = {
    'Content-Type': 'application/json'
  }

  constructor(private http: HttpClient) { 
    this.verificaPermissoes()
  }

  verificaPermissoes() {
    this.apiURL = 'http://localhost:35599'
    this.http.get(`${this.apiURL}/v1/user/verificaSeEstaLogado`, {
      withCredentials: true,
    }).subscribe(resultado => this.analisaResultado(resultado));
  }

  analisaResultado(resultado) {
    if (resultado.Success) {
      //redirecionar para a url informada
      window.location.href = "https://g1.com.br/";
      this.title = "logado com sucesso"
    }
  }

  ngOnInit(): void {
  }

}
