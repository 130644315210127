import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ConfigComponent } from './../config/config.component';
import { FormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './../login.service';
import { AlertaComponent } from './../alerta/alerta.component';
import { InputSenhaComponent } from './../assets/input-senha/input-senha.component';
import { Router } from "@angular/router"
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';



@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  conf: any = {}
  resultado: any = {}
  username: string = ""
  infoCodigoRegistro = ""
  step = "checaUsername"
  mostrarSenhas: boolean = false

  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Brazil];
  inputRegistroTelefone = "inputRegistroTelefone"

  numeroTelefoneValido: boolean = false

  telefoneForm = new FormGroup({
		telefone: new FormControl(undefined, [Validators.required])
	});
  

  novaSenha: string = ""
  confirmacaoSenha: string = ""
  telefone: string = ""
  email: string = ""
  nomeCompleto: string = ""
  foto: string = ""
  documento: string = ""

  constructor(
    private configs: ConfigComponent,
    private loginService: LoginService,
    private router: Router,
  ) {
    this.conf = this.configs.registro
  }

  @ViewChildren('alerta') alerta: QueryList<AlertaComponent>;
  @ViewChildren('inputSenha') inputSenhaRegistro: QueryList<InputSenhaComponent>;
  @ViewChildren('inputSenhaConfirmacao') inputSenhaRegistroConfirmacao: QueryList<InputSenhaComponent>;

  ngOnInit(): void {
  }


  ngAfterViewInit() {
  }

  verificaSeUsernameEstaDisponivel() {
    this.loginService.verificaSeUsernameEstaDisponivel(this.username)
      .subscribe(resultado => this.analisaResultadoUsernameEstaDisponivel(resultado));
  }

  analisaResultadoUsernameEstaDisponivel(resultado) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.step = "preencheInfos"

      this.inputSenhaRegistro.toArray()[0].tipo = "2"
      this.inputSenhaRegistroConfirmacao.toArray()[0].tipo = "confirmacao"

    } else {
      console.log(this.conf.textoUsernameOcupado)
      this.alerta.toArray()[0].geraAlerta(this.conf.textoUsernameOcupado, "danger")
    }
  }

  validaNomeUsuario() {
    if (this.username.length < 4) {
      return false
    } else {
      return true
    }
  }


  validaInputRegistro() {
    var enviar = true

    //checa se tem menos de x caracteres
    if (this.username.length < 3) {
      enviar = false
    }

    if (this.inputSenhaRegistro) {
      this.novaSenha = this.inputSenhaRegistro.toArray()[0].senha
      this.confirmacaoSenha = this.inputSenhaRegistroConfirmacao.toArray()[0].senha
      if (this.inputSenhaRegistro.toArray()[0].senha != this.inputSenhaRegistroConfirmacao.toArray()[0].senha) {
        enviar = false
      }
  
      //senha
      if (!this.inputSenhaRegistroConfirmacao.toArray()[0].validaInputSenha()) {
        enviar = false
      }  
    }

    enviar = this.validaNumeroTelefone()

    if (this.email.length < 4) {
      enviar = false
    }

    if (this.nomeCompleto.length < 4) {
      enviar = false
    }

    if (this.documento.length != 11 && this.documento.length != 13 && !this.documento.match(/^[0-9]+$/)) {
      enviar = false
    }

    return enviar
  }

  validaNumeroTelefone() {
    let valido = false
    if (this.telefoneForm.value.telefone != null) {
      if (this.telefoneForm.value.telefone.e164Number.length == 14 || this.telefoneForm.value.telefone.length == 17) {
        valido = true
      } 
    } 
    
    return valido
  }

  registroEnviar() {
    var enviar = {
      "username": this.username,
      "password": this.novaSenha,
      "phone_number": this.telefoneForm.value.telefone.e164Number,
      "name": this.nomeCompleto,
      "email": this.email,
      "picture": this.foto,
      "documento": this.documento,
    }

    this.loginService.registroEnviar(enviar)
      .subscribe(resultado => this.analisaResultadoRegistroEnviar(resultado));
  }

  analisaResultadoRegistroEnviar(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.infoCodigoRegistro = this.resultado.Dados
      this.alerta.toArray()[0].geraAlerta(this.infoCodigoRegistro, "success")
      let router = this.router
      setTimeout(function () {
        router.navigate(['/confirmarcodigo'])
      }, 5000)
    } else {
      this.alerta.toArray()[0].geraAlerta(this.conf.textoErroRegistroUsuario, "danger")
    }
  }

  toggleMostrarSenhas() {
    if (!this.mostrarSenhas) {
      $(".inputSenha").attr("type","text")
    } else {
      $(".inputSenha").attr("type","password")
    }
  }



}
