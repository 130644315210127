import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { GlobalLogoutComponent } from './globalLogout/globalLogout.component';
import { AreaRestritaComponent } from './area-restrita/area-restrita.component';
import { RegistroComponent } from './registro/registro.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { EsqueciMinhaSenhaComponent } from './esqueci-minha-senha/esqueci-minha-senha.component';
import { ConfirmacaoCodigoEsqueciMinhaSenhaComponent } from './confirmacao-codigo-esqueci-minha-senha/confirmacao-codigo-esqueci-minha-senha.component';
import { RegistrarSocialComponent } from './registrar-social/registrar-social.component';
import { ConfirmacaoUsuarioSocialComponent } from './confirmacao-usuario-social/confirmacao-usuario-social.component';
import { UsuarioComponent } from './usuario/usuario.component';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full'},
  // { path: 'login/:url', component: LoginComponent},
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'globalLogout', component: GlobalLogoutComponent},
  { path: 'registro', component: RegistroComponent},
  { path: 'confirmarcodigo', component: ConfirmacaoComponent},
  { path: 'confirmarcodigosocial', component: ConfirmacaoUsuarioSocialComponent},
  { path: 'esqueciminhasenha', component: EsqueciMinhaSenhaComponent},
  { path: 'alterarsenha', component: EsqueciMinhaSenhaComponent},
  { path: 'confirmarcodigosenha', component: ConfirmacaoCodigoEsqueciMinhaSenhaComponent},
  { path: 'areaRestrita', component: AreaRestritaComponent},
  { path: 'registrarSocial', component: RegistrarSocialComponent },
  { path: 'usuario', component: UsuarioComponent },
  // { path: ':url', redirectTo: "/login/:url"},
  // { path: '**', redirectTo: "/login"},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
  
export class AppRoutingModule { }
