
<div class="">

  <div class="">
    <div class="">

      <app-alerta #alerta></app-alerta>

      <!-- login -->
      <div id="containerLogin" *ngIf="tela == 'login'">
        <app-campos-login></app-campos-login>
      </div>

      <!-- esqueci minha senha -->
      <div id="containerEsqueciMinhasSenha" *ngIf="tela == 'esqueciMinhaSenha'">
        <app-esqueci-minha-senha></app-esqueci-minha-senha>
      </div>

      <!-- informa código esqueci minha senha -->
      <div id="containerCodigoEsqueciMinhasSenha" *ngIf="tela == 'confirmaCodigoEsqueciMinhasSenha'">
        {{infoCodigoEsqueciMinhasSenha}}
        <div class="input-group">
          <span class="input-group-addon"
            id="inputCodigoEsqueciMinhaSenha">{{placeholderCodigoEsqueciMinhasSenha}}</span>
          <input type="text" class="form-control" [(ngModel)]="codigo"
            placeholder="{{placeholderCodigoEsqueciMinhasSenha}}" aria-describedby="basic-addon1">
        </div>
        <p>{{infoSenha}}</p>
        <div class="input-group">
          <span class="input-group-addon" id="inputNovaSenha">{{placeholderNovaSenha}}</span>
          <input type="text" class="form-control" [(ngModel)]="novaSenha" placeholder="{{placeholderNovaSenha}}"
            aria-describedby="basic-addon1">
        </div>
        <button class="{{classeBotaoLogin}}" [disabled]="!validaInputsConfirmaCodigo()"
          (click)="confirmaCodigoEsqueciMinhasSenha()">
          {{textoBotaoCodigoEsqueciMinhasSenha}}
        </button>
        <button class="{{classeBotaoLogin}}" (click)="reenviaCodigoEsqueciMinhaSenha()">
          {{textoBotaoReenviaCodigoEsqueciMinhasSenha}}
        </button>
        <div class="text-center containerBotaoVoltarParaLogin">
          <button type="button" class="btn btn-default btn-xs" (click)="voltaLogin()">Voltar para login</button>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
