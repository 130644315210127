    <div class="loader text-center" *ngIf="loggedIn===true">
      <i style="color: white; margin-top: 50px" class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
    </div>

    <div *ngIf="loggedIn===false">
      
      <app-alerta #alertaLogin></app-alerta>
      
      {{conf.infoLogin}}

      <div class="input-group">
        <span class="input-group-addon" id="inputLogin">{{conf.placeholderLogin}}</span>
        <input id="inputLogin" type="text" class="form-control" [(ngModel)]="login" placeholder="{{conf.placeholderLogin}}"
          aria-describedby="basic-addon1">
      </div>
      <app-input-senha #inputSenha></app-input-senha>


      <button id="btnLogin" class="{{conf.classeBotaoLogin}}" [disabled]="!validaInputsLogin()" (click)="fazLogin()">
        {{conf.textoBotaoLogin}}
      </button>

      <hr>

      <p>Ou faça login pela sua rede social:</p>

      <div>
        <!-- <button type="button" (click)="signInWithFB()" *ngIf="loggedIn===false" class="btn btn-primary"> Facebook </button> -->
        <button id="btnLoginFacebook" type="button" (click)="signInWithFB()" class="btn btn-primary"> Facebook </button>
      </div>

      <div id="containerBotaoRegistro" *ngIf="conf.permiteRegistro" style="margin-top: 20px">
        <a class="{{conf.classeBotaoRegistro}}" routerLink="/registro">
          {{conf.textoBotaoRegistro}}
        </a>
      </div>

      <div class="text-center containerBotaoEsqueciMinhaSenha">
        <button type="button" class="btn btn-default btn-xs" (click)="esqueciMinhaSenha()">Esqueci minha
          senha</button>
      </div>
    </div>
