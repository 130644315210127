<div class="">

  <div class="">
    <div class="">

      <app-alerta #alerta></app-alerta>
      {{conf.infoEsqueciMinhaSenha}}
      <div class="input-group">
        <span class="input-group-addon" id="inputLoginEsqueciMinhaSenha">{{conf.placeholderLogin}}</span>
        <input type="text" class="form-control" [(ngModel)]="login" placeholder="{{conf.placeholderLogin}}"
          aria-describedby="basic-addon1">
      </div>
      <button class="{{conf.classeBotaoLogin}}" (click)="recuperaSenha()">
        {{conf.textoBotaoConfirmaEsqueciMinhaSenha}}
      </button>
      <app-btn-voltar-login></app-btn-voltar-login>
      <app-btn-confirmar-codigo-esqueci-minha-senha></app-btn-confirmar-codigo-esqueci-minha-senha>
    </div>
  </div>
</div>
