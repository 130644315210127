import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigComponent } from './../config/config.component';
import { LoginService } from './../login.service';
import { AlertaComponent } from './../alerta/alerta.component';
import { Router } from "@angular/router"
@Component({
  selector: 'app-esqueci-minha-senha',
  templateUrl: './esqueci-minha-senha.component.html',
  styleUrls: ['./esqueci-minha-senha.component.css']
})
export class EsqueciMinhaSenhaComponent implements OnInit {
  conf: any = {}
  resultado: any = {}
  login: string

  @ViewChild('alerta', { static: true }) alerta: AlertaComponent;

  constructor(
    private configs: ConfigComponent,
    private loginService: LoginService,
    private router: Router,
  ) {
    this.conf = this.configs.camposEsqueciMinhaSenha
  }

  ngOnInit(): void {
  }

  

  recuperaSenha() {
    this.loginService.recuperaSenha(this.login)
      .subscribe(resultado => this.analisaResultadoEsqueciMinhaSenha(resultado));
  }

  analisaResultadoEsqueciMinhaSenha(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {
      // this.tela = "confirmaCodigoEsqueciMinhasSenha"
      // this.infoCodigoEsqueciMinhasSenha = this.resultado.Dados
      this.alerta.geraAlerta(this.resultado.Dados, "success")
      let router = this.router
      setTimeout(function () {
        router.navigate(['/confirmarcodigosenha'])
      }, 5000)
    } else {
      this.alerta.geraAlerta(this.conf.textoErroRecuperarSenha, "danger")
    }
  }

  voltaLogin() {
    // this.tela = "login"
  }

}
