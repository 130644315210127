import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertaComponent } from './../alerta/alerta.component';
import { LoginService } from './../login.service';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  tela = "login"

  //fazer buscar essas informações antes de exibir
  logoURL = "https://www.doutorsofa.com.br/img/site/logo4.png"
  
  textoCodigoReenviado = "Código reenviado"
  textoErroReenviarCodigo = "Erro ao reenviar código"
  


  infoCodigoEsqueciMinhasSenha = ""
  placeholderCodigoEsqueciMinhasSenha = "Código"
  placeholderNovaSenha = "Nova senha"
  textoBotaoCodigoEsqueciMinhasSenha = "Confirmar código"
  textoBotaoReenviaCodigo = "Reenviar"

  
  codigo: string = ""
  novaSenha: string = ""

  url: string
  resultado: any = {}

  @ViewChild('alerta', { static: true }) alerta: AlertaComponent;

  readonly apiURL: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private loginService: LoginService,
  ) { }

  user: any = {}
  loggedIn: any = {}
  ngOnInit(): void {
    //caso um dia queira fazer por localStorage em vez de cookie
    // var localTokenID = localStorage.getItem("doutorSofaID")
    this.activatedRoute.params.subscribe(params => {
      this.url = params['url']
      if (this.url == "") {
        this.url = "/"
      }
    })

    this.verificaSeEstaLogado()
  }
  
 

  mostraAlerta(erro: string, classe: string) {
    this.alerta.geraAlerta(erro, classe)
  }

  voltaLogin() {
    this.tela = "login"
  }

  esqueciMinhaSenha() {
    this.tela = "esqueciMinhaSenha"
  }

  login: string
  senha: string

  confirmaCodigoEsqueciMinhasSenha() {
    var enviar = {
      "username": this.login,
      "password": this.novaSenha,
      "confirmationCode": this.codigo
    }

    this.loginService.confirmaCodigoEsqueciMinhasSenha(enviar)
    .subscribe(resultado => this.analisaResultadoConfirmaEsqueciMinhaSenha(resultado));
  }

  textoSucessoRecuperarSenha: string
  textoErroRecuperarSenha: string
  analisaResultadoConfirmaEsqueciMinhaSenha(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.tela = "login"
      this.mostraAlerta(this.textoSucessoRecuperarSenha, "success")
    } else {
      this.mostraAlerta(this.textoErroRecuperarSenha, "danger")
    }
  }

  reenviaCodigoEsqueciMinhaSenha() {
    this.loginService.reenviaCodigo(this.login)
      .subscribe(resultado => this.analisaResultadoReenviaCodigo(resultado));
  }

  analisaResultadoReenviaCodigo(resultado) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.mostraAlerta(this.textoCodigoReenviado, "success")
    } else {
      this.mostraAlerta(this.textoErroReenviarCodigo, "danger")
    }
  }

  verificaSeEstaLogado() {
    this.loginService.verificaSeEstaLogado()
      .subscribe(resultado => this.analisaResultado(resultado))
  }

  analisaResultado(resultado) {
    if (resultado.Success == true) {
      this.router.navigate(['/usuario'])
    }
  }

}
