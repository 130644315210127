import { Component, OnInit } from '@angular/core';
import { LoginService } from './../login.service';
import { ConfigComponent } from './../config/config.component';
import { ActivatedRoute, Router } from "@angular/router"

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  conf: any = {}
  userImage: string = ""
  usuario: any = {}

  constructor(
    private loginService: LoginService,
    private configs: ConfigComponent,
    private router: Router,
  ) {
    this.conf = this.configs.usuario
  }

  ngOnInit(): void {
    this.verificaSeEstaLogado()
  }

  verificaSeEstaLogado() {
    this.loginService.verificaSeEstaLogado()
      .subscribe(resultado => this.analisaResultado(resultado))
  }

  logOut() {
    this.loginService.logout()
      .subscribe(resultado => this.analisaResultadoLogout(resultado))
  }

  globalLogOut() {
    this.loginService.globalLogout()
      .subscribe(resultado => this.analisaResultadoLogout(resultado))
  }

  analisaResultadoLogout(resultado) {
    if (resultado.Success == true) {
      document.cookie = "DoutorSofa='Usuário deslogado com sucesso'"

      let logout = this
      setTimeout(function () {
        logout.router.navigate(['/'])  
      }, 1000)
      
    }
  }

  analisaResultado(resultado) {
    if (resultado == false || resultado.Success == false) {
      this.router.navigate(['/'])
    } else {
      this.usuario = resultado.Dados
    }
  }

  alterarSenha() {
    this.router.navigate(['/alterarsenha'])
  }

  verificaSePodeAlterarSenha() {
    if (this.usuario.origem != "Facebook") {
      return true
    }
    return false
  }
}
