import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigComponent } from '../config/config.component';
import { LoginService } from '../login.service';
import { AlertaComponent } from '../alerta/alerta.component';
import { InputSenhaComponent } from './../assets/input-senha/input-senha.component';
import { Router } from "@angular/router"

@Component({
  selector: 'app-confirmacao-codigo-esqueci-minha-senha',
  templateUrl: './confirmacao-codigo-esqueci-minha-senha.component.html',
  styleUrls: ['./confirmacao-codigo-esqueci-minha-senha.component.css']
})
export class ConfirmacaoCodigoEsqueciMinhaSenhaComponent implements OnInit {

  
  conf: any = {}
  username: string = ""
  codigo: string = ""
  senha: string = ""
  resultado: any = {}

  @ViewChild('alerta', { static: true }) alerta: AlertaComponent;
  @ViewChild('inputSenha', { static: true }) inputSenha: InputSenhaComponent;

  constructor(
    private configs: ConfigComponent,
    private loginService: LoginService,
    private router: Router,
  ) {
    this.conf = this.configs.confirmacaoSenha
  }

  ngOnInit(): void {
    this.inputSenha.tipo = "2"
  }

  reenviaCodigo() {
    this.loginService.recuperaSenha(this.username)
      .subscribe(resultado => this.analisaResultadoReenviaCodigo(resultado));
  }

  analisaResultadoReenviaCodigo(resultado) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.alerta.geraAlerta(resultado.Dados, "success")
    } else {
      this.alerta.geraAlerta(this.conf.textoErroReenviarCodigo, "danger")
    }
  }

  validaInputsReenviaCodigo() {
    var enviar = true

    //checa se tem menos de x caracteres
    if (this.username.length < 3) {
      enviar = false
    }

    return enviar
  }

  validaInputsAlteraSenha() {
    var enviar = true

    //checa se tem menos de x caracteres
    if (this.username.length < 3) {
      enviar = false
    }

    if (!this.inputSenha.validaInputSenha()) {
      enviar = false
    }

    return enviar
  }


  confirmaCodigoEsqueciMinhasSenha() {
    var enviar = {
      "username": this.username,
      "password": this.inputSenha.senha,
      "confirmationCode": this.codigo
    }

    this.loginService.confirmaCodigoEsqueciMinhasSenha(enviar)
    .subscribe(resultado => this.analisaResultadoConfirmaEsqueciMinhaSenha(resultado));
  }

  analisaResultadoConfirmaEsqueciMinhaSenha(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {

      this.alerta.geraAlerta(this.conf.textoSucessoRecuperarSenha, "success")
    } else {
      this.alerta.geraAlerta(this.conf.textoErroRecuperarSenha, "danger")
    }
  }


}
