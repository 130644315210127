import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { promises } from 'dns';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  apiURL: string

  headers = {
    'Content-Type': 'application/json',
  }

  constructor(
    private http: HttpClient,
  ) {
    this.apiURL = '/loginApi'
  }

  fazLogin(login: string, senha: string) {
    let cookie = this.getCookie('DoutorSofa')
    if (cookie != "") {
      
    }
    var enviar = {
      "username": login,
      "password": senha
    }

    return this.http.post(`${this.apiURL}/v1/user/logaUsuario`, enviar, {
      "headers": this.headers
    })
  }

  recuperaSenha(login: string) {
    return this.http.get(`${this.apiURL}/v1/user/esqueciMinhaSenha/${login}`, {
      headers: this.headers
    })
  }

  confirmaCodigoEsqueciMinhasSenha(enviar: object) {
    return this.http.post(`${this.apiURL}/v1/user/confirmaEsqueciMinhaSenha`, enviar, {
      headers: this.headers
    })
  }

  reenviaCodigo(login: string) {
    return this.http.get(`${this.apiURL}/v1/user/reenviarConfirmacao/${login}`, {
      headers: this.headers
    })
  }

  verificaSeUsernameEstaDisponivel(username: string) {
    return this.http.get(`${this.apiURL}/v1/user/verificaSeUsuarioExiste/${username}`, {
      headers: this.headers
    })
  }

  registroEnviar(enviar: object) {
    return this.http.post(`${this.apiURL}/v1/user/registraUsuario`, enviar, {
      headers: this.headers
    })
  }

  confirmaCodigoRegistro(enviar: object) {
    return this.http.post(`${this.apiURL}/v1/user/confirmaUsuario`, enviar, {
      headers: this.headers
    })
  }

  verificaSeEstaLogado() {
    let cookie = this.getCookie('DoutorSofa')
      return this.http.get(`${this.apiURL}/v1/user/verificaSeEstaLogado`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': cookie,
          'Cache-Control': 'no-cache',
        }
      })
 
  }

  logout() {
    let cookie = this.getCookie('DoutorSofa')
    return this.http.get(`${this.apiURL}/v1/user/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookie,
      }
    })
  }

  globalLogout() {
    let cookie = this.getCookie('DoutorSofa')
    return this.http.get(`${this.apiURL}/v1/user/globalLogout`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookie,
      }
    })
  }


  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) return parts.pop().split(';').shift();
    return parts.pop().split(';').shift();
  }

  // SOCIAL

  verificaSocial(user) {
    let enviar = {
      "ID": user.id,
      "Name": user.name,
      "Password": user.senha,
      "PhoneNumber": user.telefone,
      "Email": user.email,
      "PhotoURL": user.photoUrl,
      "Documento": user.documento,
      "AuthToken": user.authToken,
      "Provider": user.provider,
    }

    return this.http.post(`${this.apiURL}/v1/user/social`, enviar, {
      headers: this.headers
    })
  }
}
