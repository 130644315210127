import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigComponent } from './../config/config.component';
import { LoginService } from './../login.service';
import { AlertaComponent } from './../alerta/alerta.component';

@Component({
  selector: 'app-registrar-social',
  templateUrl: './registrar-social.component.html',
  styleUrls: ['./registrar-social.component.css']
})
export class RegistrarSocialComponent implements OnInit {
  conf: any = {}
  resultado: any = {}
  user: any = {}
  email: string
  telefone: string

  @ViewChild('alerta', { static: true }) alerta: AlertaComponent;

  constructor(
    private route:  ActivatedRoute,
    private router: Router,
    private configs: ConfigComponent,
    private loginService: LoginService,
  ) {
    this.conf = this.configs.registrarSocial
  }

  ngOnInit(): void {
    this.user = history.state
    this.user.telefone = ""
    this.user.documento = ""
    if (typeof this.user.name == 'undefined') {
      this.router.navigate(['/'])
    }
  }

  validaInputRegistro() {
    var enviar = true

    if (this.user.telefone.length < 11) {
      enviar = false
    }

    if (this.user.email.length < 4) {
      enviar = false
    }

    if (this.user.documento.length != 11 && this.user.documento.length != 13 ) {
      enviar = false
    }

    return enviar
  }

  registroEnviar() {
    this.loginService.verificaSocial(this.user)
      .subscribe(resultado => this.analisaResultadoRegistroEnviar(resultado));
  }

  analisaResultadoRegistroEnviar(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.alerta.geraAlerta(this.resultado.Dados, "success")
      let router = this.router
      let user = this.user
      setTimeout(function () {
        console.log(user)
        router.navigate(['/confirmarcodigosocial'], { state: user })
      }, 5000)
    } else {
      this.alerta.geraAlerta(this.conf.textoErroRegistroUsuario, "danger")
    }
  }



}
