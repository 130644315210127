import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-confirmar-codigo',
  templateUrl: './btn-confirmar-codigo.component.html',
  styleUrls: ['./btn-confirmar-codigo.component.css']
})
export class BtnConfirmarCodigoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
