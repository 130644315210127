import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigComponent } from '../config/config.component';
import { LoginService } from '../login.service';
import { AlertaComponent } from '../alerta/alerta.component';
import { Router } from "@angular/router"

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.css']
})
export class ConfirmacaoComponent implements OnInit {

  conf: any = {}
  username: string = ""
  codigo: string = ""
  resultado: any = {}

  @ViewChild('alerta', { static: true }) alerta: AlertaComponent;

  constructor(
    private configs: ConfigComponent,
    private loginService: LoginService,
    private router: Router,
  ) {
    this.conf = this.configs.confirmacaoRegistro
  }

  ngOnInit(): void {
  }

  reenviaCodigo() {
    this.loginService.reenviaCodigo(this.username)
      .subscribe(resultado => this.analisaResultadoReenviaCodigo(resultado));
  }

  analisaResultadoReenviaCodigo(resultado) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.alerta.geraAlerta(resultado.Dados, "success")
    } else {
      this.alerta.geraAlerta(this.conf.textoErroReenviarCodigo, "danger")
    }
  }

  validaInputsCodigoRegistro() {
    var enviar = true

    //checa se tem menos de x caracteres
    if (this.codigo.length < 6) {
      enviar = false
    }

    return enviar
  }

  validaInputsReenviaCodigo() {
    var enviar = true

    //checa se tem menos de x caracteres
    if (this.username.length < 3) {
      enviar = false
    }

    return enviar
  }

  confirmaCodigoRegistro() {
    var enviar = {
      "username": this.username,
      "confirmationCode": this.codigo
    }

    this.loginService.confirmaCodigoRegistro(enviar)
      .subscribe(resultado => this.analisaResultadoConfirmaRegistro(resultado));
  }

  analisaResultadoConfirmaRegistro(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success) {
      this.alerta.geraAlerta(this.conf.textoSucessoConfirmaRegistroUsuario, "success")
      let router = this.router
      setTimeout(function () {
        router.navigate(['/'])
      }, 5000)
    } else {
      this.alerta.geraAlerta(this.conf.textoErroConfirmaRegistroUsuario, "danger")
    }
  }

}
