<div *ngIf="tipo == '1'">
  <div class="input-group">
    <span *ngIf="conf.placeholderSenha.length > 0" class="input-group-addon">{{conf.placeholderSenha}}</span>
    <input id="senhaTipo1" type="password" class="inputSenha form-control" [(ngModel)]="senha"
      placeholder="{{conf.placeholderSenha}}" aria-describedby="basic-addon1">

  </div>
  <input type="checkbox" name="mostrarSenhaTipo1" id="mostrarSenhaTipo1" [(ngModel)]="mostrarSenhaTipo1"
    (click)="toggleMostrarSenhaTipo1()">
  <label style="margin-left: 5px;" for="mostrarSenhaTipo1">Mostrar senha</label>
</div>


<div *ngIf="tipo == '2'">
  <div class="form-group">
    <label for="inputRegistroSenha">{{conf.placeholderSenha}}</label>
    <input id="senhaTipo2" type="password" class="inputSenha form-control" id="inputRegistroSenha" [(ngModel)]="senha"
      placeholder="{{conf.placeholderSenha}}">
    <span style="font-size: 10px;">{{conf.infoSenha}}</span>
  </div>
</div>

<div *ngIf="tipo == 'confirmacao'">
  <div class="form-group">
    <label for="inputRegistroConfirmacaoSenha">{{conf.placeholderConfirmacaoSenha}}</label>
    <input id="senhaTipoConfirmacao" type="password" class="inputSenha form-control" id="inputRegistroConfirmacaoSenha"
      [(ngModel)]="senha" placeholder="{{conf.placeholderConfirmacaoSenha}}">
  </div>
</div>
