import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ConfigComponent } from './../../config/config.component';
import { SocialAuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { LoginService } from './../../login.service';
import { InputSenhaComponent } from './../../assets/input-senha/input-senha.component';
import { AlertaComponent } from './../../alerta/alerta.component';
import { ActivatedRoute, Router } from "@angular/router"

@Component({
  selector: 'app-campos-login',
  templateUrl: './campos-login.component.html',
  styleUrls: ['./campos-login.component.css']
})
export class CamposLoginComponent implements OnInit {

  resultado: any = {}
  conf: any = {}
  user: SocialUser
  loggedIn: boolean = false
  login: string = ""
  senha: string = ""
  url: string = ""

  socialID: string

  constructor(
    private configs: ConfigComponent,
    private authService: SocialAuthService,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

    this.conf = this.configs.camposLogin

    //limpa todos os cookies com esse nome, independente do path
    this.eraseCookieFromAllPaths("DoutorSofa")
    // var cookies = document.cookie.split("; ");
    // for (var c = 0; c < cookies.length; c++) {
    //     var d = window.location.hostname.split(".");
    //     while (d.length > 0) {
    //         var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
    //         var p = location.pathname.split('/');
    //         document.cookie = cookieBase + '/';
    //         while (p.length > 0) {
    //             document.cookie = cookieBase + p.join('/');
    //             p.pop();
    //         };
    //         d.shift();
    //     }
    // }

    this.activatedRoute.queryParams.
      subscribe({
        next: valores => {
          if (typeof valores.redirectTo != 'undefined') {
            this.url = valores.redirectTo
          } else {
            this.url = "REDIRECTTO"
          }
        }
      })
  }

  eraseCookieFromAllPaths(name) {
    // This function will attempt to remove a cookie from all paths.
    var pathBits = location.pathname.split('/');
    var pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.user = user
      this.loggedIn = (user != null)
      this.socialID = user.id
    });
  }

  @ViewChildren('inputSenha') inputSenhaRegistro: QueryList<InputSenhaComponent>;
  @ViewChildren('alertaLogin') alerta: QueryList<AlertaComponent>;

  validaInputsLogin() {
    var enviar = true

    //checa se o código tem menos de x caracteres
    if (this.login.length < 4) {
      enviar = false
    }

    return enviar
  }

  fazLogin() {
    // console.log(this.inputSenhaRegistro.toArray()[0].senha)
    this.loginService.fazLogin(this.login, this.inputSenhaRegistro.toArray()[0].senha)
      .subscribe(resultado => this.analisaResultado(resultado));
  }

  analisaResultado(resultado: object) {
    this.resultado = resultado
    if (this.resultado.Success == true) {
      localStorage.removeItem("permissoes")

      this.fezLogin(this.resultado.Dados)
    } else {
      this.alerta.toArray()[0].geraAlerta(this.resultado.Erro, "danger")
    }
  }

  signInWithFB(): void {
    let classe = this
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(function (user) {
        // console.log(user)
        classe.verificaUsernameSocialID(user)
      })
  }

  signOut(): void {
    this.authService.signOut();
  }

  verificaUsernameSocialID(user) {
    this.loginService.verificaSocial(user)
      .subscribe(resultado => this.analisaResultadoUsernameEstaDisponivelSocial(resultado));
  }

  analisaResultadoUsernameEstaDisponivelSocial(resultado) {
    this.resultado = resultado

    if (this.resultado.Success) {
      this.fezLogin(this.resultado.Dados)

    } else {
      if (this.resultado.Erro == "Precisa registrar") {
        //handle registrar
        this.router.navigate(['/registrarSocial'], { state: this.user })
      }
      if (this.resultado.Erro == "Precisa confirmar") {
        //handle registrar
        this.router.navigate(['/confirmarcodigosocial'], { state: this.user })
      }
    }
  }

  esqueciMinhaSenha() {
    this.router.navigate(['/esqueciminhasenha'])
  }

  fezLogin(dados: string) {
    document.cookie = dados
    
    if (this.url == "") {
      this.url = "REDIRECTTO"
    }

    window.location.href = this.url;
  }

}
