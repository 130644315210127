import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css']
})
export class AlertaComponent implements OnInit {

  constructor() { }

  textoAlerta: string
  classe: string

  @ViewChild('alertaCont', { read: ElementRef }) alerta: ElementRef;


  ngOnInit(): void {
  }

  geraAlerta(texto: string, classe: string) {
    this.textoAlerta = texto
    this.classe = classe
    let dom = this.alerta.nativeElement

    $(dom).fadeIn()
    
    setTimeout(function () {
      $(dom).fadeOut()
    }, 5000)
  }

}
